import {
  FooterContent,
  FooterLinks,
  FooterText,
  LeftContent,
  LinksWrapper,
  RightsServiceWrap,
} from './styled';

export default function BillingFooter() {
  return (
    <>
      <LinksWrapper>
        <FooterLinks
          href="/termsofuse.aspx"
          id="termscondition"
          target="_blank"
        >
          TERMS AND CONDITIONS
        </FooterLinks>
        <FooterLinks
          href="/privacypolicy.aspx"
          id="privacypolicy"
          target="_blank"
        >
          PRIVACY POLICY
        </FooterLinks>
        <FooterLinks href="/contact" id="contactus" target="_blank">
          CONTACT US
        </FooterLinks>
      </LinksWrapper>
      <FooterContent>
        <LeftContent>
          <FooterText>
            Bold LLC, City View Plaza Tower II, Suite 6000 48 Calle 165,
            Guaynabo, PR 00968
          </FooterText>
          <FooterText>
            Auxiliant S.à.r.l. 1A, rue Thomas Edison, L-1445 Strassen,
            Luxembourg.
          </FooterText>
        </LeftContent>
        <RightsServiceWrap>
          <FooterText isCopyrightText>
            {`©2007-${new Date().getFullYear()} FlexJobs. All Rights Reserved.`}
          </FooterText>
          <FooterText isCopyrightText>
            Customer Service: 1-866-991-9222
          </FooterText>
        </RightsServiceWrap>
      </FooterContent>
    </>
  );
}
