import { device } from '@styles/device.styled';
import styled, { css } from 'styled-components';

interface FeaturedWrapProps {
  gridCol?: string;
  blog?: boolean;
  smallAside?: boolean;
  mobApplyGrid?: boolean;
  columnSpace?: string;
}

interface StyledDiv {
  fullwidth?: boolean;
  customWidth?: string;
  layout?: boolean;
  shadow?: boolean;
  smallAside?: boolean;
  padding?: boolean;
}

export const FeaturedWrap = styled.div<FeaturedWrapProps>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.gridCol ||
    (props.blog ? '58.33% 41.67%' : props.smallAside ? '75% 25%' : '1fr 1fr')};
  column-gap: ${({ columnSpace }) => columnSpace};

  .left,
  .right {
    padding: ${(props) => (props.blog ? '0 1.2rem' : '')};
  }

  ${device.mobileTab} {
    display: block;

    .left,
    .right {
      padding: 0;
      box-shadow: none;
    }
    display: ${(props) => props.mobApplyGrid && 'grid'};
  }
`;
export const FullWidthPage = styled.main`
  width: 100%;
`;
export const StyledContainerMd = styled.main<StyledDiv>`
  max-width: ${({ fullwidth, customWidth }) =>
    fullwidth ? null : customWidth ? customWidth : '117rem'};
  width: 100%;
  margin: 0 auto;
  padding: 0 1.5rem;
  scroll-margin-top: 16.5rem;

  ${device.mediumdesktopView} {
    max-width: ${({ fullwidth }) => (fullwidth ? null : '132rem')};
  }
`;
export const LeftSec = styled.div<StyledDiv>`
  padding: ${(props) => props.padding && '0 1.2rem 1.5rem'};
  ${({ theme: { LayoutStyle }, shadow }) =>
    LayoutStyle &&
    css`
      box-shadow: ${shadow &&
      `${LayoutStyle.Colors.leftSectionShadow} 0 0.2rem 0 0.2rem`};
    `};
`;

export const RightSection = styled.div`
  padding: 0.8rem 0 1.6rem 1.6rem;
`;

export const Title = styled.span`
  display: none;
`;

export const SkipLink = styled.a`
  color: #fff;
  background-color: #424242f2;
  padding: 0.8rem 2rem;
  font-size: 1.6rem;
  border-radius: 0.4rem;
  border: 0.1rem solid #fff;
  z-index: 9999;
  top: 0;
  transition: transform 0.2s;
  text-align: left;
  overflow: hidden;
  left: 0;
  position: fixed;
  transform: translateY(-100%);
  cursor: pointer;
  text-decoration: none;
  ${device.smallDesktopView} {
    &:focus,
    &:hover,
    &:active {
      transform: translateY(0%);
      outline: none;
      text-decoration: none;
      z-index: 11111;
      color: #fff;
    }
  }
`;
