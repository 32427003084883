import {
  clearCookies,
  clearlocalStorage,
  getClientSettingValue,
  setClientSettings,
} from '@components/common';
import BillingFooter from '@components/common/billingFooter';
import {
  AboutFlexJobs,
  AboutJobs,
  AboutJobsAuth,
  AuthMbExpertApplyNav,
  AuthMbNav,
  AuthNavChild,
  EmployerNav,
  EmployerUnAuthNav,
  ExpertApplyAuthNavChild,
  FindCandidates,
  FlexibleJobs,
  JOBS_COUNT_PATH,
  JobSearch,
  MbNav,
  NavChild,
  Resources,
  UnAuthNav,
  _CONSTANTS,
  footerExternalLink,
} from '@components/common/constant';
import {
  experimentViewedEvent,
  navClickEvents,
} from '@components/common/event-tracker';
import FooterRibbon from '@components/common/footerRibbon';
import { GlobalContext } from '@components/globalContext';
import { IDeviceType, IUserID } from '@components/shared.types';
import {
  Footer,
  Header,
  SemFooter,
  SemHeader,
} from '@license-admin/boldfjcomponents';
import { width } from '@styles/device.styled';
import useMediaQuery from '@utils/useMediaQuery';
import { ExperimentContext } from 'contexts/ExperimentContext';
import { usePremiumUser } from 'contexts/PremiumUserContext';
import { updateExperimentVariant } from 'helper/experiments/experimentActions';
import { FJPortalExperiments } from 'helper/experiments/experimentConstants';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { Children, ReactNode, useContext, useEffect, useState } from 'react';
import { newRelicJS } from '../../utils/newRelicClient';
import {
  FeaturedWrap,
  FullWidthPage,
  LeftSec,
  RightSection,
  SkipLink,
  StyledContainerMd,
  Title,
} from './styled';

interface IProps {
  children: ReactNode;
  shadow?: boolean;
  smallAside?: boolean;
  blog?: boolean;
  padding?: boolean;
  gridCol?: string;
  rightSideVisible?: boolean;
  isLoggedIn?: boolean | null;
  isEmployerLogin?: boolean | null;
  isEmployerLoginPage?: boolean;
  headerProps?: Array<string>;
  deviceType?: IDeviceType;
  isPremiumUser?: boolean;
  mobApplyGrid?: boolean;
  headerSearch?: boolean;
  columnSpace?: string;
  isPartnerPage?: boolean;
  partnerImgPath?: string;
  noHeaderFooter?: boolean;
  leftSectionrole?: string;
  rightSectionrole?: string;
  isBillingFooter?: boolean;
  isHideFooter?: boolean;
  isSemPage?: boolean;
  fullWidthPage?: boolean;
  id?: string;
  handleThemeChange?: any;
  currentTheme?: any;
  noRighrSideWidget?: boolean;
  jobDetailVariantWithoutHeader?: boolean;
  popularKeywordsExpVariantObj?: {
    experimentId: string;
    variant: number;
    variantname?: string;
    name?: string;
    isConducted: boolean;
  } | null;
}
export const Layout = (props: IProps) => {
  const { expertApplyExperimentObj } = useContext(ExperimentContext);
  const { isUserPremium } = usePremiumUser();
  const isPremium = props.isPremiumUser || isUserPremium;

  const isExpertApplyExperimentRunning =
    expertApplyExperimentObj?.isExpertApplyExperimentRunning;
  const defaultFirstName = 'My';
  const defaultLastName = 'Account';
  const { isUserLoggedIn, getUpdateUserDetail } = useContext(GlobalContext);
  const isMobileView =
    useMediaQuery(width.tablet) || props.deviceType === 'mobile';
  const [userName, setUserName] = useState<string>('');
  const [rootURL, setRootURL] = useState<string>('');
  const [userIdsNav, setUserIdsNav] = useState<IUserID | undefined>(undefined);
  const [userEmail, setUserEmail] = useState<string>('');
  const router = useRouter();
  const blob_img_path = process.env.NEXT_PUBLIC_CONTENT_URL + '/images';
  const footerEmployerNav = [AboutFlexJobs, FindCandidates, Resources];
  const footerAuthNavLinks = [FlexibleJobs, AboutJobsAuth, JobSearch];
  const footerNavLinks = [FlexibleJobs, AboutJobs, JobSearch];
  const loggedInStatus = (props && props.isLoggedIn) || isUserLoggedIn;

  const SapphireLogo = {
    height: 60,
    width: 133,
    logoSrc:
      props.currentTheme === 'wlb'
        ? `${blob_img_path}/logo-wlb.png`
        : `${blob_img_path}/fj-logo.svg`,
    title: 'FlexJobs: Search The Best Remote Work From Home and Flexible Jobs',
  };

  const logoutUser = async () => {
    if (window && window.LOGIN) {
      // To logout from the ROR
      await fetch('/logoutv2');
      await window.LOGIN.Accounts.logOutV2();
      try {
        // window.localStorage.clear();
        clearlocalStorage();
        window.sessionStorage.clear();
        clearCookies();
      } catch (error) {
        // Empty catch block
        // This catch block intentionally left empty
      }
      router.push('/login');
      getUpdateUserDetail();
    }
  };

  useEffect(() => {
    if (props.popularKeywordsExpVariantObj?.isConducted) {
      const fetchObj =
        props.deviceType === 'desktop'
          ? FJPortalExperiments.fjMostPopularSearchesDesktop
          : FJPortalExperiments.fjMostPopularSearchesMobile;
      experimentViewedEvent(
        fetchObj.id,
        fetchObj.name,
        fetchObj.variantNameArr?.[
          `${props.popularKeywordsExpVariantObj.variant}`
        ],
        undefined
      );
      updateExperimentVariant(
        fetchObj.id,
        props.popularKeywordsExpVariantObj.variant
      );
    }
  }, [props.popularKeywordsExpVariantObj]);

  useEffect(() => {
    const getUserInfoClaims = async () => {
      const claims: any = getClientSettingValue(_CONSTANTS.CLAIMS);
      let userInfo: any = claims && (await JSON.parse(claims));
      if ((!userInfo || !userInfo?.memberid) && window.LOGIN) {
        let { claims } = await window.LOGIN.Accounts.getClaims();
        userInfo = claims;
        setClientSettings({
          key: _CONSTANTS.CLAIMS,
          value: JSON.stringify(claims),
        });
      }
      const { firstName, lastName, email, memberid, user_uid } = userInfo || {};
      if (firstName || lastName) {
        setUserName(`${firstName} ${lastName}`);
      } else {
        setUserName(`${defaultFirstName} ${defaultLastName}`);
      }
      setUserIdsNav({
        memberId: parseInt(memberid),
        userId: user_uid,
      });
      setUserEmail(email);
      getUpdateUserDetail();
    };

    (loggedInStatus || isUserLoggedIn) && getUserInfoClaims();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInStatus, isUserLoggedIn]);

  useEffect(() => {
    if (window && window.location) {
      setRootURL(window.location.origin);
    }
  }, []);

  const mbNav = () => {
    if (isPremium) {
      if (isExpertApplyExperimentRunning) {
        return AuthMbExpertApplyNav;
      } else {
        return AuthMbNav;
      }
    } else {
      return MbNav;
    }
  };

  const navChild = (() => {
    if (props.isEmployerLogin) {
      return EmployerNav;
    }

    if (isPremium) {
      return isExpertApplyExperimentRunning
        ? ExpertApplyAuthNavChild
        : AuthNavChild;
    }
    return NavChild;
  })();

  return (
    <div>
      <SkipLink href="#content" className="skip-link screen-reader-text">
        Skip to content
      </SkipLink>
      {!props.noHeaderFooter &&
        (props.isSemPage ? (
          <SemHeader isLoggedIn={isUserLoggedIn} isMobileView={isMobileView} />
        ) : props.jobDetailVariantWithoutHeader ? (
          ''
        ) : (
          <Header
            navChild={navChild}
            isAuthUser={loggedInStatus || isUserLoggedIn}
            {...SapphireLogo}
            userName={userName}
            logoutUser={logoutUser}
            isMobileView={isMobileView}
            isHeader={props.headerSearch || false}
            headerProps={props.headerProps}
            nextJsApp={
              ['qa', 'reg'].indexOf(process.env.NEXT_PUBLIC_ENV || '') > -1
                ? true
                : false
            }
            unAuthNavChild={
              props.isEmployerLogin
                ? EmployerUnAuthNav
                : props.isPartnerPage
                ? []
                : UnAuthNav
            }
            isEmployerLogin={props.isEmployerLogin}
            partnerImgPath={props.partnerImgPath}
            mbNav={mbNav()}
            isPremium={isPremium}
            jobCountPath={JOBS_COUNT_PATH}
            urlSlug={router.query}
            jobsSearchUrl={process.env.NEXT_PUBLIC_JOBS_URL_RJ}
            keywordSearchURL={rootURL}
            userIds={userIdsNav}
            messageFetchUrl={process.env.NEXT_PUBLIC_FJ_URL}
            navEvents={navClickEvents}
            showThemeToggle={
              ['qa', 'reg'].indexOf(process.env.NEXT_PUBLIC_ENV || '') > -1
                ? true
                : false
            }
            currentTheme={props.currentTheme}
            handleThemeChange={props.handleThemeChange}
            isExpertApply={isExpertApplyExperimentRunning}
            isPopularSearchExp={false}
          />
        ))}
      {props.fullWidthPage ? (
        <FullWidthPage>{props.children}</FullWidthPage>
      ) : (
        <StyledContainerMd layout id="content">
          {props.children}
        </StyledContainerMd>
      )}

      {!props.noHeaderFooter && !props.isHideFooter ? (
        !props.isBillingFooter ? (
          props.isSemPage ? (
            <SemFooter isMobileView={isMobileView} />
          ) : (
            <Footer
              isAuthUser={loggedInStatus || isUserLoggedIn}
              footerNavLinks={
                props.isEmployerLogin
                  ? footerEmployerNav
                  : props.isLoggedIn
                  ? footerAuthNavLinks
                  : footerNavLinks
              }
              isMobileView={isMobileView}
              {...SapphireLogo}
              footerExternalLink={footerExternalLink}
              isPremiumUser={isPremium}
            />
          )
        ) : (
          <BillingFooter />
        )
      ) : null}
      {props.isEmployerLoginPage && <FooterRibbon />}
      <Title>Next App</Title>
      {process.env.NEXT_PUBLIC_ENV == 'prod' && (
        <Script
          id="newrelic-js"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{ __html: newRelicJS }}
        />
      )}
    </div>
  );
};

export const FlexLayout = (props: IProps) => {
  const isMobileView =
    useMediaQuery(width.tablet) || props.deviceType === 'mobile';
  const [left, right] = Children.toArray(props.children);

  return (
    <FeaturedWrap {...props}>
      <LeftSec
        role={props.leftSectionrole}
        className="left"
        padding={props?.padding}
      >
        {left}
      </LeftSec>

      {(isMobileView && !props.rightSideVisible) ||
      props.noRighrSideWidget ? null : (
        <RightSection role={props.rightSectionrole} className="right">
          {right}
        </RightSection>
      )}
    </FeaturedWrap>
  );
};
